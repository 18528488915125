.trial {
	margin-bottom: 54px;

	@include phone {
		padding: 0 32px;
		width: 100%;
	}

	&__form {
		display: flex;
		align-items: center;
		column-gap: 16px;

		@include phone {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		@media (max-width: 374px) {
			flex-direction: column;
		}

		.form-field {
			margin: 0 16px 0 0;
			width: 335px;

			@include phone-small {
				margin: 0 0 16px;
				width: 100%;
			}
		}
	}
}
