.feature-list-v2 {
	padding-top: 72px;
	padding-bottom: 64px;

	@include phone-landscape {
		padding-top: 64px;
	}

	@include phone-portrait {
		padding-top: 64px;
	}

	&__content {
		margin: 0 auto;
		padding: 0 120px;
		max-width: 1440px;
		display: flex;
		flex-direction: column;
		align-items: center;

		@include tablet {
			padding: 0 48px;
		}

		@include phone-landscape {
			padding: 0 56px;
		}

		@include phone-portrait {
			padding: 0 16px;
		}

		@include tablet-portrait {
			padding: 0;
		}
	}

	&__feature {
		width: 368px;

		@include tablet {
			width: 288px;
		}

		@include phone-landscape {
			width: 100%;
		}

		@include tablet-portrait {
			width: 326px;
		}

		@include phone-portrait {
			width: 100%;
		}
	}

	&__feature-title-section {
		display: flex;
	}

	&__feature-description {
		padding-top: 12px;
		@include font-base-18($n-17);
	}

	&__feature-title {
		padding-left: 12px;
	}

	&__list {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		column-gap: 32px;
		row-gap: 40px;
	}

	&__title {
		padding-bottom: 40px;
		text-align: center;
		@include font-base-39($n-17, 600);

		@include phone-landscape {
			@include font-base-30($n-17, 600);
		}

		@include tablet-portrait {
			@include font-base-30($n-17, 600);
		}

		@include phone-portrait {
			@include font-base-30($n-17, 600);
		}
	}

	ul {
		list-style-type: none;
	}
}
