.partners-v2 {
	margin: 0 auto;
	padding: 16px 0;
	max-width: 1440px;
	background-color: $white;

	&__content {
		padding: 0 120px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		row-gap: 20px;
		column-gap: 36px;

		@include tablet {
			padding: 0 48px;
		}

		@include tablet-portrait {
			padding: 0 40px;
		}

		@include phone-landscape {
			padding: 0 56px;
		}

		@include phone-portrait {
			padding: 0 16px;
		}
	}

	&__placeholder {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	img {
		width: auto;
		height: 40px;

		@include tablet-portrait {
			width: auto;
			height: 40px;
		}

		@include phone {
			width: auto;
			height: 40px;
		}

		@include phone-portrait {
			width: auto;
			height: 40px;
		}
	}
}
