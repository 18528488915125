.services {
	text-shadow: 0 -1px 0 rgba(37, 16, 70, 0.8);
	background-color: $p-17;
	background-image: radial-gradient(
			800px 400px at 20% 100%,
			rgba(137, 89, 217, 0.45),
			transparent 60%
		),
		radial-gradient(
			1800px 660px at 80% 0,
			rgba(137, 89, 217, 0.45),
			transparent 40%
		);

	@include large-laptop {
		background-image: radial-gradient(
				800px 400px at 30% 100%,
				rgba(137, 89, 217, 0.45),
				transparent 60%
			),
			radial-gradient(
				1800px 660px at 70% 0,
				rgba(137, 89, 217, 0.45),
				transparent 40%
			);
	}

	@include phone-portrait {
		background-image: radial-gradient(
				800px 400px at 20% 100%,
				rgba(137, 89, 217, 0.3),
				transparent 60%
			),
			radial-gradient(
				1800px 660px at 80% 0,
				rgba(137, 89, 217, 0.3),
				transparent 40%
			);
	}

	&__content {
		padding: 64px 120px 60px 120px;
		margin: 0 auto;
		max-width: 1440px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		@include tablet {
			padding-left: 48px;
			padding-right: 48px;
		}

		@include phone-landscape {
			padding-left: 56px;
			padding-right: 56px;
		}

		@include tablet-portrait {
			padding-left: 40px;
			padding-right: 40px;
		}

		@include phone-portrait {
			padding-left: 16px;
			padding-right: 16px;
		}
	}

	&__description {
		padding-bottom: 44px;
		max-width: 760px;
		text-align: center;
		@include font-base-18($n-35);

		@include tablet {
			max-width: 768px;
		}

		@include tablet-portrait {
			min-width: 686px;
		}
	}

	&__list {
		display: flex;
		justify-content: center;
		column-gap: 32px;
		row-gap: 40px;

		@include tablet-portrait {
			flex-wrap: wrap;
		}

		@include phone-landscape {
			flex-wrap: wrap;
		}

		@include phone-portrait {
			flex-wrap: wrap;
		}
	}

	&__title {
		padding-bottom: 20px;
		text-align: center;
		@include font-base-39($n-17, 600);
	}

	&__service {
		max-width: 268px;
		display: flex;
		flex: 1;
		flex-direction: column;
		align-items: left;
		text-align: left;

		@include tablet {
			max-width: 208px;
		}

		@include phone-landscape {
			min-width: 100%;
		}

		@include tablet-portrait {
			min-width: 328px;
		}

		@include phone-portrait {
			min-width: 100%;
		}
	}

	&__service-title {
		padding: 12px 0;
		@include font-base-22($n-17, 600);
	}

	a {
		padding-top: 12px;
		text-decoration: underline;
		cursor: pointer;
		@include font-base-16($g-55, 600);

		&:hover {
			color: $white;
		}
	}

	img {
		width: fit-content;
		height: 40px;
	}

	h1,
	h3,
	p {
		color: $white;
	}

	ul {
		list-style-type: none;
	}
}
