.instruction {
	max-width: 1440px;
	padding: 0 120px;
	display: flex;
	justify-content: space-between;
	column-gap: 132px;

	@include tablet {
		padding: 0 48px;
		column-gap: 112px;
	}

	@include phone-landscape {
		padding: 0 56px;
		flex-direction: column-reverse;
	}

	@include tablet-portrait {
		padding: 0 40px;
		flex-direction: column-reverse;
		column-gap: 112px;
	}

	@include phone-portrait {
		padding: 0 16px;
		flex-direction: column-reverse;
	}

	&__images {
		position: relative;
		max-width: 468px;
		height: auto;
		justify-content: flex-start;
		flex: 9;
		@include tablet {
			width: 60%;
			height: 100%;
		}

		@include phone-landscape {
			margin: auto;
			max-width: 100%;
			min-width: 100%;
		}

		@include tablet-portrait {
			margin: auto;
			max-width: 420px;
			min-width: 420px;
		}

		@include phone-portrait {
			width: 100%;
			max-width: 100%;
			min-width: 100%;
		}
	}

	&__image {
		width: 100%;
		height: auto;

		&--commerce {
			width: 568px;

			@include tablet {
				width: 448px;
			}

			@include tablet-portrait {
				width: 448px;
			}

			@include phone {
				width: 328px;
				min-width: 100%;
			}

			@include phone-portrait {
				width: 328px;
				min-width: 100%;
			}
		}
	}

	&__image-bg {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 140%;
		height: auto;
		transform: translate(-50%, -50%);
		z-index: -1;
	}

	&__info {
		display: flex;
		flex: 11;
		flex-direction: column;
		justify-content: center;

		@include phone {
			flex-direction: column;
		}

		@include tablet-portrait {
			padding-bottom: 40px;
		}

		@include phone-landscape {
			padding-bottom: 40px;
		}

		@include phone-portrait {
			padding-bottom: 40px;
		}
	}

	&__description {
		padding-bottom: 20px;
		max-width: 568px;
		@include font-base-18($n-35);

		@include tablet-portrait {
			max-width: 100%;
		}

		@include phone-landscape {
			max-width: 100%;
		}

		@include phone-portrait {
			max-width: 100%;
		}
	}

	&__title {
		margin-bottom: 20px;
		@include font-base-39($n-17, 600);

		@include phone-landscape {
			padding-right: 50px;
			@include font-base-30($n-17, 600);
		}

		@include tablet-portrait {
			@include font-base-30($n-17, 600);
		}

		@include phone-portrait {
			@include font-base-30($n-17, 600);
		}
	}

	&--right {
		@include phone-landscape {
			flex-direction: column;
		}

		@include tablet-portrait {
			flex-direction: column;
		}

		@include phone-portrait {
			flex-direction: column;
		}

		.instruction__info {
			order: -1;
		}

		.instruction__images {
			@include tablet {
				width: 50%;
				height: 100%;
			}
		}
	}

	span {
		white-space: nowrap;
	}

	.button {
		padding: 6px 12px;
		width: fit-content;
		height: 36px;
		display: flex;
		justify-content: left;
		column-gap: 8px;
		font-size: 18px;

		svg path {
			fill: $n-17;
		}

		@include phone-portrait {
			height: unset;
			justify-content: space-between;
		}
	}
}
