.instructions {
	padding: 69px 0 58px;

	&__content {
		margin: 0 auto;
		max-width: 1440px;
		display: flex;
		flex-direction: column;
		row-gap: 64px;
	}

	&__title {
		margin-bottom: 16px;
		@include font-base-39($n-17, 600);

		@include tablet-portrait {
			max-width: 472px;
		}

		@include phone {
			@include font-base-31($n-17, 600);
		}
	}

	&__description {
		margin-bottom: 56px;
		max-width: 771px;
		@include font-base-18($n-17);

		@include tablet {
			max-width: 726px;
		}

		@include tablet-portrait {
			max-width: 522px;
			font-size: 16px;
		}

		@include phone {
			margin-bottom: 40px;
			font-size: 16px;
		}

		@include phone-landscape-iphone6 {
			max-width: 576px;
		}
	}
}
