@import "./trial/Trial";

.intro {
	position: relative;
	background-color: $p-98;

	&__bg {
		position: absolute;
		max-width: 1440px;
		left: calc(50% + 200px) !important;
		transform: translate(-50%);

		@include phone-landscape {
			object-fit: cover;
			object-position: 30%;
			left: unset !important;
			transform: unset;
		}

		@include tablet-portrait {
			object-fit: cover;
			object-position: 30%;
			left: unset !important;
			transform: unset;
		}

		@include phone-portrait {
			object-fit: cover;
			object-position: 20%;
			left: unset !important;
			transform: unset;
		}
	}

	&__content {
		position: relative;
		margin: 0 auto;
		max-width: 1440px;
		padding: 48px 120px 38px 120px;
		display: flex;
		justify-content: left;

		@include tablet {
			padding: 48px 48px 38px 48px;
		}

		@include phone-landscape {
			flex-direction: column;
			padding: 48px 40px 0 40px;
		}

		@include tablet-portrait {
			flex-direction: column;
			padding: 48px 40px 0 40px;
		}

		@include phone-portrait {
			flex-direction: column;
			padding: 48px 16px 0 16px;
		}
	}

	&__image-container {
		position: absolute;
		left: 50%;
		bottom: 0;
		display: flex;

		img {
			width: auto;
			height: 464px;
		}

		@include phone-landscape {
			position: static;
			margin-left: auto;
			margin-right: auto;

			img {
				padding: 0 16px;
				width: 100%;
				height: auto;
			}
		}

		@include tablet-portrait {
			position: static;
			margin-left: auto;
			margin-right: auto;

			img {
				padding: 0 90px;
				width: 100%;
				height: auto;
			}
		}

		@include phone-portrait {
			position: static;
			margin-left: auto;
			margin-right: auto;

			img {
				padding: 0;
				width: 100%;
				height: auto;
			}
		}
	}

	&__subtitle {
		margin-top: 20px !important;
		margin-bottom: 28px;
		max-width: 468px;
		text-shadow: 0 1px 0 $white;
		@include font-base-20($n-35);

		@include phone-landscape {
			max-width: 80%;
		}

		@include tablet-portrait {
			max-width: 468px;
		}

		@include phone-portrait {
			max-width: 100%;
		}
	}

	&__text {
		max-width: 50%;
		padding-top: 36px;
		padding-right: 32px;
		display: flex;
		flex-direction: column;

		@include phone-landscape {
			max-width: 100%;
			padding: 0;
			align-items: center;
			text-align: center;

			.trial {
				margin-bottom: 29px;
			}
		}

		@include tablet-portrait {
			max-width: 100%;
			padding: 0;
			align-items: center;
			text-align: center;

			.trial {
				margin-bottom: 32px;
			}
		}

		@include phone-portrait {
			max-width: 100%;
			padding: 0;
			align-items: center;
			text-align: center;

			.trial {
				margin-bottom: 32px;
				padding: 0;

				.trial__form {
					flex-direction: row;
				}
			}
		}
	}

	&__title {
		text-shadow: 0 1px 0 $white;
		@include font-base-49($n-17, 600);

		@include phone-landscape {
			@include font-base-39($n-17, 600);
		}

		@include tablet-portrait {
			max-width: 712px;
			@include font-base-39($n-17, 600);
		}

		@include phone-portrait {
			@include font-base-39($n-17, 600);
		}

		span {
			@include font-base-49($p-48, 600);

			@include tablet-portrait {
				@include font-base-39($p-48, 600);
			}

			@include phone-landscape {
				@include font-base-39($p-48, 600);
			}

			@include phone-portrait {
				@include font-base-39($p-48, 600);
			}
		}
	}
}
