.use-cases {
	background-color: $p-98;

	&__content {
		padding: 64px 120px 60px 120px;
		margin: 0 auto;
		max-width: 1440px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		flex-direction: column;

		@include tablet {
			padding-left: 48px;
			padding-right: 48px;
		}

		@include phone-landscape {
			padding-left: 56px;
			padding-right: 56px;
		}

		@include tablet-portrait {
			padding-left: 40px;
			padding-right: 40px;
		}

		@include phone-portrait {
			padding-left: 16px;
			padding-right: 16px;
		}
	}

	&__description {
		padding-bottom: 44px;
		max-width: 968px;
		@include font-base-18($n-35);

		@include tablet {
			max-width: 768px;
		}

		@include tablet-portrait {
			min-width: 686px;
		}
	}

	&__list {
		display: flex;
		justify-content: center;
		column-gap: 32px;
		row-gap: 40px;

		@include tablet-portrait {
			flex-wrap: wrap;
		}

		@include phone-landscape {
			flex-wrap: wrap;
		}

		@include phone-portrait {
			flex-wrap: wrap;
		}
	}

	&__title {
		padding-bottom: 20px;
		@include font-base-39($n-17, 600);

		@include tablet {
			max-width: 768px;
		}

		@include tablet-portrait {
			@include font-base-30($n-17, 600);
		}

		@include phone-landscape {
			@include font-base-30($n-17, 600);
		}

		@include phone-portrait {
			@include font-base-30($n-17, 600);
		}
	}

	&__use-case {
		max-width: 268px;
		display: flex;
		flex: 1;
		align-items: center;
		text-align: center;
		flex-direction: column;

		@include tablet {
			max-width: 208px;
		}

		@include phone-landscape {
			min-width: 100%;
		}

		@include tablet-portrait {
			min-width: 328px;
		}

		@include phone-portrait {
			min-width: 100%;
		}
	}

	&__use-case-title {
		padding: 12px 0;
		@include font-base-24($n-17, 600);
	}

	a {
		padding-top: 12px;
		text-decoration: underline;
		cursor: pointer;
		@include font-base-16($p-48, 600);

		&:hover {
			color: $n-17;
		}
	}

	ul {
		list-style-type: none;
	}
}
