.cta-banner-v2 {
	margin: 0 auto;
	max-width: 1440px;
	padding: 0 120px;

	@include tablet {
		padding: 0 48px;
	}

	@include phone-landscape {
		padding: 0;
	}

	@include tablet-portrait {
		padding: 0 48px;
	}

	@include phone-portrait {
		padding: 0;
	}

	&--commerce,
	&--communications,
	&--eventPlatforms {
		@include phone-landscape {
			padding: 0 56px;
		}

		@include tablet-portrait {
			padding: 0 48px;
		}

		@include phone-portrait {
			padding: 0 16px;
		}
	}

	&__button {
		&:hover {
			transform: translateY(-6px);
		}

		@include phone-portrait {
			&--primary,
			&--secondary {
				width: 100%;
			}
		}

		&--primary {
			height: 44px;
			box-shadow:
				0 1px 2px 0 rgba(0, 0, 0, 0.4),
				inset 0 2px 0 0 rgba(255, 255, 255, 0.2),
				inset 0 -2px 0 0 rgba(0, 0, 0, 0.2);
			border: solid 1px $p-37;
			background-image: linear-gradient(to bottom, $p-60 0%, $p-48 100%);
		}

		&--secondary {
			padding-right: 12px;
			padding-left: 12px;
			height: 44px;
			box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
			border: solid 1px $n-75;
			background-image: linear-gradient(to bottom, $white 0%, $n-95 100%);
			font-family: "Work Sans" !important;
		}
	}

	&__buttons {
		position: relative;
		display: flex;
		column-gap: 16px;
		row-gap: 16px;
		position: relative;

		.button {
			position: relative;
			z-index: 1;
			cursor: pointer;
		}

		.button::before {
			content: "";
			position: absolute;
			top: -10px;
			left: -10px;
			right: -10px;
			bottom: -10px;
			z-index: 0;
			background: transparent;
		}

		@include phone-portrait {
			flex-direction: column;
			width: 100%;
		}

		&--commerce,
		&--communications,
		&--eventPlatforms {
			white-space: nowrap;
			width: unset;
			padding-top: 20px;
			flex-direction: row;
		}
	}

	&__content {
		overflow: hidden;
		position: relative;
		padding: 44px;
		border-radius: 24px;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-size: cover;
		background-position: top;
		box-shadow: inset 0 9px 28px -4px rgba(37, 16, 70, 0.5);

		@include tablet {
			padding-left: 48px;
			padding-right: 48px;
		}

		&::after {
			border-radius: inherit;
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 2;
			box-shadow: inset 0 9px 28px -4px rgba(37, 16, 70, 0.5);
			pointer-events: none;
		}

		&--commerce,
		&--communications,
		&--eventPlatforms {
			&::after {
				box-shadow: inset 0 9px 28px -4px rgba(37, 16, 70, 0.32);
			}
		}
	}

	&__content-bg-image {
		border-radius: inherit;
		box-shadow: inherit;
		object-fit: cover;
	}

	&__description {
		position: relative;
		padding: 12px 20% 32px 20%;
		text-align: center;
		text-shadow: 0 1px 0 $white;
		@include font-base-18($n-17);

		@include phone-landscape {
			padding: 20px 58px;
		}

		@include tablet-portrait {
			padding: 20px 90px 30px 90px;
		}

		@include phone-portrait {
			padding: 28px 6px 20px 6px;
		}
	}

	&__title {
		position: relative;
		text-align: center;
		text-shadow: 0 1px 0 $white;
		@include font-base-39($n-17, 600);

		@include tablet-portrait {
			@include font-base-30($n-17, 600);
		}

		@include phone-landscape {
			@include font-base-30($n-17, 600);
		}

		@include phone-portrait {
			@include font-base-30($n-17, 600);
		}
	}
}
