@import "../styles/mixins";

@import "../components/home/instructions/Instructions";
@import "../components/home/intro/Intro";
@import "../components/partnersV2/PartnersV2";
@import "../components/useCases/UseCases";
@import "../components/instruction/Instruction";
@import "../components/featureListV2/FeatureListV2";
@import "../components/ctaBanner/CTABanner";
@import "../components/services/Services";

.home {
	font-family: "Work Sans";
	overflow: hidden;

	&__curvy-bg {
		position: relative;
		padding-bottom: 64px;
		background: linear-gradient(
			to bottom,
			transparent 0%,
			transparent 64px,
			$p-98 64px,
			$p-98 100%
		);

		&::before {
			content: "";
			position: absolute;
			width: 100%;
			height: 128px;
			background: linear-gradient(to bottom, $p-98 50%, transparent 50%);
			border-radius: 50%;

			@include phone {
				height: 80px;
			}
		}

		@include phone {
			background: linear-gradient(
				to bottom,
				transparent 0%,
				transparent 40px,
				$p-98 40px,
				$p-98 100%
			);
		}
	}

	.blog {
		padding-top: 40px;
		padding-bottom: 64px;

		@include phone {
			padding-bottom: 0;
		}

		.carousel-wrapper {
			height: 340px;
			margin-bottom: 55px;
		}
	}

	@include tablet {
		.feature-list {
			flex-wrap: nowrap;
			justify-content: center;
		}
	}

	@include tablet-portrait {
		.feature-list {
			justify-content: center;
		}
	}

	@include phone {
		.feature-list {
			justify-content: center;
		}
	}
}
